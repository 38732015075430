.drawer {

    // estimated on wallet linking drawer
    --header-size: 70px;

    section {
        box-shadow: 0px 0px 20px 2px var(--color-green-neon-300);

        & > header, & > div {
            margin-left: var(--margin-100);
            margin-right: var(--margin-100);
        }

        & > div {
            // unfortunately, current drawer styles result of height higher than real drawer size
            height: calc(100% - var(--header-size));
        }
    }

    header {
        margin-top: var(--margin-100);

        h2 {
            font-size: var(--font-size-800);
            font-weight: var(--font-weight-bold);
        }
    }
}
